import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Collapsible from "@moneris-portal/collapsible";
import { ToastMessage, addToastMessage } from "@moneris-portal/toastmessage";
import createTranslator from "../../../../util/translationHelper";
import EditModalUserProfile from "./EditModalUserProfile";
import { PhoneValidator } from "../../util";
import messages from "../Translations/messages";
import { userActions } from "../../../../contextProviders/constant";
import PencilLogo from "./assets/PencilLogo";
import { capitaliseFirstLetter } from "../../../../util/helper";
import handleWindowClose from "../../../..//util/windowClose";

import "./index.scss";

class UserAccount extends React.Component {
  translate = createTranslator(messages);

  constructor(props) {
    super(props);
    this.state = {
      enableEditUserProfile: false,
      enableDisputeNotificationEdit: false,
      editEditEmail: false,
      activeItem: "",
      showPinValidateNotification: false,
      showExpiredCodeNotification: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    const {
      validatePinSuccessFlag,
      userProfileUpdatedErrorFlag,
      resetSameEmail,
      sameEmailFlag,
    } = this.props;

    const { activeItem } = this.state;

    if (
      prevProps.validatePinSuccessFlag === null &&
      validatePinSuccessFlag !== null
    ) {
      this.setState({
        showPinValidateNotification: true,
      });
      this.handleModalVisibility();
    }

    if (
      prevProps.userProfileUpdatedErrorFlag === null &&
      userProfileUpdatedErrorFlag !== null &&
      activeItem !== ""
    ) {
      addToastMessage({
        variant: userProfileUpdatedErrorFlag ? "success" : "danger",
        message: userProfileUpdatedErrorFlag
          ? `${capitaliseFirstLetter(activeItem)} ${this.translate("success_update_profile")}`
          : `${this.translate("error_updating_profile")} ${activeItem}.`,
      });
      this.setState({ enableEditUserProfile: false });
    }

    if (!prevProps.sameEmailFlag && sameEmailFlag) {
      this.handleModalVisibility();
      resetSameEmail();
    }
  };

  setExpiredCodeNotification = () => {
    this.setState({
      showExpiredCodeNotification: true,
    });
  };

  handleModalVisibility = () => {
    handleWindowClose(false);
    this.setState({
      enableEditUserProfile: false,
      editEditEmail: false,
    });
  };

  updateUserProfile = (userProfileObj) => {
    const { dispatch } = this.props;
    dispatch({
      type: userActions.updateUserInfo,
      body: { ...userProfileObj },
    });
  };

  hideNotification = () => {
    this.setState({
      showPinValidateNotification: false,
      showProfileNotification: false,
      showExpiredCodeNotification: false,
    });
  };

  editUserProfileClickHandler = (event) => {
    event.stopPropagation();
    this.setState({
      enableEditUserProfile: true,
      activeItem: this.translate("profile"),
    });
    handleWindowClose(true);
  };

  editEmailClickHandler = (event) => {
    event.stopPropagation();
    this.setState({
      editEditEmail: true,
      activeItem: this.translate("error_email"),
    });
    handleWindowClose(true);
  };

  render() {
    const {
      enableEditUserProfile,
      // Now the email editing feature is disabled
      // activeItem,
      // showProfileNotification,
      // editEditEmail,
      // showPinValidateNotification,
      // showExpiredCodeNotification,
    } = this.state;
    const {
      userProfileUpdatedErrorFlag,
      accountInfo,
      containerId,
      // Now the email editing feature is disabled
      // updateUserEmail,
      // userEmailUpdatedFlag,
      // errorCode,
      // validatePinSuccessFlag,
    } = this.props;

    const { firstName, lastName, businessPhone, userEmail, businessExtension } =
      accountInfo;

    const maskedNumberBusiness = PhoneValidator(businessPhone);
    return (
      <div className="user_profile_container">
        <div className="user_profile_content_container" id={containerId}>
          <div id="item1" data-scroll-id={this.translate("user_profile")}>
            <Collapsible
              title={
                <div className="user_profile_title">
                  <span>{this.translate("user_profile")}</span>
                  <button
                    className={"user_profile_button"}
                    onClick={this.editUserProfileClickHandler}
                  >
                    <PencilLogo />
                    <span>{this.translate("edit")}</span>
                  </button>
                </div>
              }
              active
            >
              <div className="user_profile_container_body">
                <div>
                  <div className="body_row">
                    <div className="body_cell one">
                      {this.translate("user_first_name")}
                    </div>
                    <div className="body_cell two cap">
                      <span>{firstName}</span>
                    </div>
                  </div>
                  <div className="body_row">
                    <div className="body_cell one">
                      {this.translate("user_last_name")}
                    </div>
                    <div className="body_cell two cap">
                      <span>{lastName}</span>
                    </div>
                  </div>
                  <div className="body_row">
                    <div className="body_cell one">
                      {this.translate("contact_phone_number")}
                    </div>
                    <div className="body_cell two phone">
                      <span
                        className={maskedNumberBusiness === "" ? "_empty" : ""}
                      >
                        {maskedNumberBusiness}
                      </span>
                    </div>
                  </div>
                  <div className="body_row">
                    <div className="body_cell one">{this.translate("ext")}</div>
                    <div className="body_cell two phone">
                      <span>{businessExtension}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>
          </div>
          <div id="item2" data-scroll-id={this.translate("email_address")}>
            <Collapsible
              title={
                <div className="user_profile_title">
                  <span>{this.translate("email_address")}</span>
                  <button
                    className={classNames("user_profile_button", "disabled")}
                    onClick={this.editEmailClickHandler}
                    disabled
                  >
                    <PencilLogo color="#c3c3c3" />
                    <span>{this.translate("edit")}</span>
                  </button>
                </div>
              }
              active
            >
              <div className="user_profile_container_body">
                <div>
                  <div className="body_row">
                    <div className="body_cell one">
                      {this.translate("email_address")}
                    </div>
                    <div className="body_cell two">
                      <div title={userEmail}>{userEmail}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapsible>
          </div>
        </div>
        <ToastMessage
          maximumToasts={0}
          duration={3000}
          style={{ top: "4rem" }}
        />
        {/* Now the email editing feature is disabled */}
        {/* {validatePinSuccessFlag !== null &&
          activeItem !== "" &&
          showPinValidateNotification && (
            <Notification
              variant={validatePinSuccessFlag ? "success" : "danger"}
            >
              {validatePinSuccessFlag
                ? `${activeItem} ${
                    activeItem === translate("error_email")
                      ? translate("success_update")
                      : translate("success_update_profile")
                  }`
                : errorCode === 500
                ? translate("error_something_wrong")
                : `${
                    activeItem === translate("error_email")
                      ? translate("error_updating")
                      : translate("error_updating_profile")
                  } ${activeItem}.`}
            </Notification>
          )} */}
        {/* {showExpiredCodeNotification && (
          <Notification variant="danger">
            {translate("error_code_exipred")}
          </Notification>
        )} */}
        {enableEditUserProfile && (
          <EditModalUserProfile
            showModal={enableEditUserProfile}
            handleModalVisibility={this.handleModalVisibility}
            updateUserProfile={this.updateUserProfile}
            firstName={firstName}
            lastName={lastName}
            phoneNumber={businessPhone}
            phoneExtension={businessExtension}
            userProfileUpdatedErrorFlag={userProfileUpdatedErrorFlag}
          />
        )}
        {/* Now the email editing feature is disabled */}
        {/* {editEditEmail && (
          <EditModalEmail
            showModal={editEditEmail}
            handleModalVisibility={this.handleModalVisibility}
            validatePin={validatePin}
            updateUserEmail={updateUserEmail}
            userEmailUpdatedFlag={userEmailUpdatedFlag}
            validatePinSuccessFlag={validatePinSuccessFlag}
            errorCode={errorCode}
            setExpiredCodeNotification={this.setExpiredCodeNotification}
          />
        )} */}
      </div>
    );
  }
}

UserAccount.defaultProps = {
  updateDisputeNotificationPinSuccessFlag: null,
  validatePinSuccessFlag: null,
  disputeValidatePinFlag: null,
  updateSelfServeBankingEligibility: () => null,
  updateSelfServeBankingEligibilitySuccessFlag: null,
  updateSelfServeProfileUpdateSuccessFlag: null,
  selfServeRefundLimitAmount: 0,
  selfServeRefundEnabled: false,
  selfServeBankEligibilityEnabled: false,
  roleId: -1,
  userEmailUpdatedFlag: null,
  errorCode: null,
  userProfileUpdatedErrorFlag: null,
  canEditDisputeNotification: false,
  notificationLanguage: "en",
  notificationEmail: "",
  resetUserState: () => null,
  copyRequestNotificationsEnabled: false,
  chargebackNotificationsEnabled: false,
  resetSameEmail: () => null,
  sameEmailFlag: false,
  updateSelfServeProfileUpdate: () => null,
};

UserAccount.propTypes = {
  updateDisputeNotificationPinSuccessFlag: PropTypes.oneOf([null, true, false]),
  selfServeRefundEnabled: PropTypes.bool,
  selfServeRefundLimitAmount: PropTypes.number,
  updateSelfServeBankingEligibility: PropTypes.func,
  updateSelfServeBankingEligibilitySuccessFlag: PropTypes.oneOf([
    null,
    true,
    false,
  ]),
  roleId: PropTypes.number,
  selfServeBankEligibilityEnabled: PropTypes.bool,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }),
  }).isRequired,
  updateUserPhone: PropTypes.func.isRequired,
  validatePinSuccessFlag: PropTypes.oneOf([null, true, false]),
  userProfileUpdatedErrorFlag: PropTypes.oneOf([null, true, false]),
  userEmailUpdatedFlag: PropTypes.oneOf([null, true, false]),
  updateUserEmail: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
  validatePin: PropTypes.func.isRequired,
  userPhone: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  businessExtension: PropTypes.string.isRequired,
  businessPhone: PropTypes.string.isRequired,
  errorCode: PropTypes.any,
  canEditDisputeNotification: PropTypes.bool,
  notificationLanguage: PropTypes.string,
  notificationEmail: PropTypes.string,
  resetUserState: PropTypes.func,
  copyRequestNotificationsEnabled: PropTypes.bool,
  chargebackNotificationsEnabled: PropTypes.bool,
  resetSameEmail: PropTypes.func,
  sameEmailFlag: PropTypes.bool,
  disputeEmailUpdateSuccessFlag: PropTypes.bool.isRequired,
  disputeCBNotificationSuccessFlag: PropTypes.bool.isRequired,
  disputeCRNotificationSuccessFlag: PropTypes.bool.isRequired,
  disputePLNotificationSuccessFlag: PropTypes.bool.isRequired,
  disputeValidatePinFlag: PropTypes.oneOf([null, true, false]),
  updateDisputeEmail: PropTypes.func.isRequired,
  updateDisputeNotification: PropTypes.func.isRequired,
  validateDisputeEmailPin: PropTypes.func.isRequired,
  updatePreferredLang: PropTypes.func.isRequired,
  sameEmailUpdated: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  featureFlags: PropTypes.object.isRequired,
  updateRefund: PropTypes.func.isRequired,
  userObj: PropTypes.object.isRequired,
  updateSelfServeProfileUpdateSuccessFlag: PropTypes.bool,
  updateSelfServeProfileUpdate: PropTypes.func,
};

export default UserAccount;
